.input-group {
  position: relative;
  width: 100%;

  label {
    display: block;
    font-weight: 600;
    margin-bottom: .5rem;
  }

  .input-error {
    float: right;
    width: fit-content;
    padding: 0.25rem;
    color: red;
  }
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"],
textarea {
  width: 100%;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 8px 16px;
  outline: none;
  transition: border-color 0.3;

  &:focus {
    border-color: $brandPrimary;
  }
}

input,
select {
  height: 32px;
}

input[type="checkbox"] {
  height: 20px;
  width: 20px;

  &:checked {
    accent-color: $brandPrimary;
  }
}

input[type="color"] {
  height: 36px;
  width: 100%;
  border: none;
  border-radius: 50%;
  padding: 0;
}

.toggle {
  display: none;
}

.toggle-control {
  display: inline-block;
  width: 40px;
  height: 24px;
  background-color: #dfdfdf;
  border-radius: 15px;
  position: relative;
  cursor: pointer;
  box-shadow: inset 2px 5px 4px rgba(0, 0, 0, 0.1);
}

.toggle-control:after {
  content: "";
  display: block;
  width: 24px;
  height: 24px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.15);
  transform: translateX(-2px);
}

.toggle:checked + .toggle-control:after {
  transform: translateX(17px);
  box-shadow: -2px 2px 5px rgba(0, 0, 0, 0.15);
}
.toggle:checked + .toggle-control {
  background-color: $brandPrimary;
}

textarea {
  resize: vertical;
}

select {
  width: 100%;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 8px 16px;
  outline: none;
  transition: border-color 0.3;

  &:focus {
    border-color: $brandPrimary;
  }
}

option {
  height: 40px;

  &:hover {
    background-color: $brandPrimary;
    color: white;
  }
}

.color-picker {
  .recent-colors {
    height: 40px;
    display: flex;
    white-space: nowrap;
    box-shadow: inset 2px 1px 4px 0px rgba(0, 0, 0, 0.12);
    min-height: 40px;
    justify-content: center;
    align-items: center;
    width: fit-content;
    padding: 0 8px;
  }

  .recent-color-dot {
    height: 28px;
    width: 28px;
    border-radius: 50%;
    margin: 0 4px;

    &:hover {
      cursor: pointer;
    }
  }
}

.file-upload {
  width: 100%;
  height: 100px;
  border: 2px dashed $brandPrimary;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: border-color 0.3s ease;
}

.file-upload.dragging {
  opacity: 0.5;
}

.file-input {
  display: none;
}

.upload-text {
  font-size: 16px;
  color: #777;
}

.radio-container {
  display: flex;
  gap: .5rem;
  align-items: center;
  label {
    font-size: .75rem;
    margin-bottom: 0;
  }
}
