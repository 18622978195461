.cancel-subscription-page {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 1rem 2rem;
    .heading-section {
        display: flex;
        flex-direction: column;
        gap: .5rem;
        .main {
            font-size: 2em;
            font-weight: 700;
        }
        .sub {
            font-size: 1.5em;
        }
    }
    .cancel-subscription {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        .cancel-form {
            max-width: 600px;
            padding: 1rem;
            display: flex;
            flex-direction: column;
            gap: 1rem;
        }
    }
}