@import "./_variables.scss";

.login-page {
  min-height: 100vh;
  justify-content: center;
  display: flex;
  align-items: center;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)), url("../assets/img/fff-login-background3.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 8px;
}

/*styles for redeem code container and media responsiveness */
.login-page-content {
}

/*desktop styles for the screen*/
@media only screen and (min-width: 900px) {
.login-page-content {
  display: flex;
  width: 80%;
  justify-content: center;
  max-width: 1200px;
  column-gap: 40px;
  }

  /*adjust the login form to not get to small on desktop devices */
  .login-page-content .login-box {
    min-width: 400px;
    height: fit-content;
    font-weight: 200 !important;
    font-family: inter;
  }

  /*adjust the font size for desktop screens and override default styles */
  .login-page-content p {
    font-size: 20px !important;
  }

  /*adjust the input selections and override default styles */
  .login-page-content input {
    height: auto !important;
    padding: 15px 20px !important;
    background-color: #f7f8f9;
    font-size: 16px;
    font-weight: 300;
  }
  .login-page-content div.login-box .link {
    font-size: 15px !important;
    font-weight: 400;
  }

}

/*-------------------------------------------------------*/

/*media styles for tablet and mobile devices */
@media only screen and (max-width: 900px) {
  .login-page-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 81%;
  }

  .login-page-content .login-content {
    padding-bottom: 40px;
  }

  /*changing the font directly on the page*/
  .login-page-content h2 {
    width: 100%;
    font-size: 3em !important; 
  }

  .login-page-content p {
    font-size: 20px !important;
  }

  .login-page-content div.login-box {
    max-width: 100% !important;
  }

  .login-page-content div.login-box .link {
    font-size: 13px !important;
  }

}



.login-box {
  max-width: 450px;
  border-radius: 10px;
  padding: 20px 20px 40px;
  background: $white;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  box-shadow: 0 12px 40px rgba(0, 0, 0, 0.12);
  max-height: 100%;
  overflow-y: auto;

  .login-logo {
    img {
      height: 2.5rem;
    }
  }
  
  .input-group {
  }

  .email-login,
  .forgot-password-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    margin-bottom: 20px;

    .input-group {
      width: 90%;
    }

    p {
      text-align: center;
    }
  }

  .social-logins {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    button {
      font-size: 15px;
      display: flex;
      gap: 0.5rem;
      justify-content: center;
      align-items: center;
      img {
        width: 1.25rem;
      }
    }
  }

  button {
    width: 90%;
  }
}

.link {
  font-size: 14px;
  text-decoration: none;
}

.divider {
  filter: opacity(50%);
}

.email-login {
  margin-bottom: 20px;
}

.login-box .social-logins button {

}
