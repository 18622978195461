$brand-color-1: #e76a25;
$brand-color-2: #475569;

$success-color: #29BD2E;

$white: #fefeff;

$brandPrimary: rgb(231, 106, 37);
$brandPrimaryLight: rgba(231, 106, 37, 0.20);
$blackPrimary: #030305;
$blackSecondary: #030305;
$brandSecondary: #fbfb15;
$blueSecondary: #314652;
$mobileMenu: #475569;
$blueTertiary: #131866;
$greySecondary: #9fa0b7;
$greyTertiary: #e2e2e2;
$bgGray: #f8f8f8;

$default_navigation_width: 3.5rem;

// Define breakpoints as a map
$breakpoints: (
  "small": 480px,
  "medium": 768px,
  "large": 1024px,
  "xlarge": 1200px,
);

// Mixin for media queries
@mixin respond-to($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);
    @media (min-width: $breakpoint-value) {
      @content;
    }
  } @else {
    @warn "Unknown breakpoint: #{$breakpoint}.";
  }
}
