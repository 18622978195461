@import "../styles/_variables.scss";

.conversations-component {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 500;
  pointer-events: none;
  width: 100%;

  @include respond-to("medium") {
    width: 300px;
    right: 5%;
  }

  @include respond-to("large") {
    width: 400px;
  }
}

.loading-overlay {
  height: 100%;
}

.chat-open-tab {
  padding: 0.5rem 2rem;
  margin: 0 1rem;
  background-color: $brandPrimary;
  color: white;
  font-size: 1.125rem;
  border-radius: 0.5rem 0.5rem 0 0;
  height: 40px;
  width: fit-content;
  pointer-events: all;
  cursor: pointer;

  @include respond-to("medium") {
    margin: 0;
    float: right;
  }
}

.chat-content {
  pointer-events: all;
  clear: both;
  background-color: white;
  border-radius: 0.5rem 0 0 0;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
  transition: height 0.3s ease;
  max-height: 500px;
  display: flex;
  flex-direction: column;
  height: 0;
}

.chat-heading {
  height: 3rem;
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    height: 2rem;
  }
}

.conversation-detail {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .chat-messages {
    max-height: 500px;
    overflow-y: auto;
    flex: 1;
  }
  .chat-message-input {
    height: 3rem;
    button {
      height: 2.5rem;
    }
  }
}

.chat-conversation-tile {
  margin: 1rem 0;
}

.conversation-message {
  margin: 0.75rem 0.5rem;
  width: fit-content;
  max-width: 80%;

  .message-card {
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
    .message-body {
      font-size: 0.875rem;
    }
  }

  .message-author {
    color: grey;
    font-weight: 300;
    font-size: 0.75rem;
    margin-bottom: 0.25rem;
  }

  &.owned {
    margin-left: auto;
    .message-card {
      background-color: $blueTertiary;
      color: white;
    }
  }
}
