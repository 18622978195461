@import "./_variables.scss";

$navigation_transition: all 0.3s ease-in-out;

.main-navigation {
  position: relative;
  height: 40px;
  background-color: $brand-color-2;
  z-index: 1000;

  @include respond-to(medium) {
    position: absolute;
    left: 0;
    height: 100vh;
    width: $default_navigation_width;
    transition: width 0.3s ease-in-out;

    &:hover {
      width: 16rem;
      #app-logo {
        img {
          width: 4rem;
        }
      }
      ul {
        li {
          p {
            opacity: 1;
            pointer-events: none;
          }
        }
      }
    }
  }

  nav {
    display: flex;
    flex-direction: row;
    height: 100%;

    @include respond-to(medium) {
      flex-direction: column;
    }
  }
  #app-logo {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: 8rem;
    img {
      width: 2rem;
      transition: $navigation_transition;
    }
  }
  .nav-links {
    height: 100%;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    background-color: $brand-color-2;

    #links-ul {
      flex: 1;
    }
    #signout-ul {
      flex-basis: 40px;
      margin-bottom: 16px;
    }

    &.open {
      display: flex;
      position: absolute;
      top: 40px;
      width: 100%;
      height: fit-content;
    }

    @include respond-to(medium) {
      display: flex;
    }
  }
  ul {
    display: flex;
    flex-direction: column;
    li {
      transition: $navigation_transition;
      button {
        background-color: transparent;
        padding: 0;
      }
      a,
      button {
        text-decoration: none;
        width: 100%;
        cursor: pointer;
        color: white;
        display: flex;
        align-items: center;
        height: 40px;

        p {
          flex: 1;
          padding-left: 0.5rem;
          font-weight: 800;
          opacity: 0;
          text-align: left;
          transition: $navigation_transition;
          white-space: nowrap;
        }

        .icon-area {
          min-width: 56px;
          display: flex;
          align-items: center;
          justify-content: center;
          .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 28px;
            width: 28px;
            border-radius: 4px;
            svg {
              height: 24px;
              width: 24px;
            }
          }
        }
        &.active {
          .icon {
            background-color: $brandPrimary;
          }
        }
        &:focus {
          outline: none;
        }
      }
      &:hover,
      &:focus-within {
        background-color: $brandPrimary;
      }
    }
  }
}
