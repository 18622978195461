@import "./variables.scss";

/*importing some fonts for the login pages so we can easily use those for now outside of the primary application */
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Outfit:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Yellowtail&display=swap');

.redeem-code {
    display: flex;
    flex-direction: column;
}

.login-page {
    .login-content {
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
        padding-bottom: 40px;
        justify-content: flex-end;
        gap: 1rem;
        h2 {
            font-size: 3.5rem;
            font-family: outfit;
        }
        p {
            font-size: 1.5rem;
        }
        .brand-icon {
            width: fit-content;
            max-height: 50px;

        }
        @include respond-to(medium) {
            margin-top: 0;
        }
    }
}

#redeem-payment {
    margin: .5rem;
    @include respond-to(medium) {
        margin: 2rem 1rem;
    }
    .redeem-payment-content {
        max-width: 1250px;
        margin: 0 auto;
    }
}

#redeem-confirm {
    margin: .5rem;

    .redeem-confirm-content {
        max-width: 1250px;
        margin: 0 auto;
    }

    .confirm-content {
        padding: 3rem 2rem;
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-around;
        align-items: center;
        gap: 2rem;
        flex-wrap: wrap;
        .content {
            flex: 1;
            &.center-content {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        @include respond-to(medium) {
            flex-direction: row;
        }
    }
    .redeem-confirm-text {
        // padding: 3rem 0;
        h2 {
            font-size: 2.5rem;
            font-weight: 800;
            padding: 1rem 0;
        }
    }
    #phone-images {
            min-width: 400px;
            max-width: 80%;
            height: auto;
            object-fit: contain;
    }
    @include respond-to(medium) {
        margin: 2rem 1rem;
    }
}

.redeem-steps {
    padding: 2rem 0 4rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 1rem;
    .step {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.5rem;
        min-width: 80px;
        text-align: center;
        height: 40px;
        .step-num {
            height: 35px;
            width: 35px;
            padding: .5rem;
            border-radius: 50%;
            align-content: center;
            // color: $;
            background-color: lightgray;
            display: flex;
            justify-content: center;
            align-items: center;
            &.complete {
                background-color: $brandPrimaryLight;
                color: $brandPrimary;
            }
            &.current {
                background-color: $brandPrimary;
                color: $white;
            }
        }
        h3 {
            font-weight: 500;
        }
    }
    .step-div {
        width: 100%;
        height: 2px;
        background-color: $brandPrimary;
        &.complete {
            background-color: $brandPrimaryLight;
        }
    }
}



#discount-redeem-form {
    padding: 3rem 2rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    #discount-redeem-text {
        flex-basis: 100%;
        
        .inner-wrap {
            padding: 1rem 2rem 1rem 0;
            display: flex;
            flex-direction: column;
            gap: 2rem;
        }
        .header {
            color: $brand-color-1;
            font-size: 2.5rem;
            font-weight: 800;
        }
        .sub-header {
            font-size: 1.25rem;
            font-weight: 300;
        }

        @include respond-to(large) {
            flex-basis: calc(100% - 500px);
        }
    }
    
    #discount-redeem-input {
        flex-basis: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        justify-content: center;
        padding: 1.5rem;
        border-radius: 1rem;
        box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.15);

        .cta-heading {
            font-size: 1.25rem;
            font-weight: 600;
        }

        @include respond-to(large) {
            padding: 2rem 2rem;
            max-width: 500px;
        }

        .product-select {
            display: flex;
            flex-direction: column;
            gap: 1rem;
        }

        .code-redeem {
            display: flex;
            gap: 0.5rem;
            height: auto;
            .input-section {
                width: 100%;
                input {
                    height: 48px;
                    padding: 1rem;
                    font-size: 1rem;
                    font-weight: 300;
                    border: 1px solid #ccc;
                    border-radius: 0.5rem;
                }
            }
            button {
                min-width: 120px;
                width:100%;
                height: 48px;
                padding: 1rem;
                cursor: pointer;
                font-weight: 300;
            }
        }
        .error {
            margin: 0.5rem 0.25rem;
            color: red;
            font-size: 0.75rem;
        }
        .proceed-cta {
            display: flex;
            gap: 8px;
            justify-content: center;
            align-items: center;
        }
    }

}

.detail-step {
    display: flex;
    gap: 1rem;
    align-items: center;
    .step-text {
        font-size: 1rem;
        font-weight: 300;
    }
    .step-num {
        height: 40px;
        width: 40px;
        padding: .5rem;
        border-radius: 50%;
        align-content: center;
        color: $white;
        background-color: #E7EAF0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

#discount-redeem-feature-boxes {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: center;
    padding: 3rem 2rem;
    .feature {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        padding: 2rem;
        border: 1px solid #ccc;
        border-radius: 0.5rem;
        min-width: 100%;
        // box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.15);
        .svg-contain {
            height: 50px;
            width: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background-color: #E7EAF0;
            svg {
                height: 30px;
                width: 30px;
                color: $brand-color-1;
                font-size: 2rem;
            }
        }
        h4 {
            font-size: 1.25rem;
            font-weight: 600;
            text-align: center;
        }
        p {
            font-size: 1rem;
            font-weight: 200;
            text-align: center;
        }
        @include respond-to(medium) {
            min-width: 350px;
        }
    }
    @include respond-to(medium) {
        gap: 1.5rem;
    }
}

#discount-redeem-feature-video {
    padding: 3rem 2rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 2rem;
    .video-text, .video {
        flex-basis: 100%;
        @include respond-to(large) {
            flex-basis: calc(50% - 2rem);
        }
    }
    #fff-video {
        aspect-ratio: 16 / 9;
        height: auto;
        width: 100%;
    }
}

#payment-logout {
    padding: 2rem 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.app-download {
    margin: 3rem 0;
    display: flex;
    justify-content: center;
    gap: 2rem;

    a {
        width: 180px;
        border: 1px solid #ccc;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            width: 90%;
            height: auto;
        }
    }

    @include respond-to(medium) {
        justify-content: start;
    }

}


.product-option {
    border-radius: 10px;
    display: flex;
    border: 1px solid lightgray;
    // box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.15);
    transition: border 0.3s ease, box-shadow 0.3s ease;
    .active-marker {
        flex-basis: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        .check-contain {
            height: 16px;
            width: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: darkgrey;
            border-radius: 4px;
            svg {
                opacity: 0;
                color: #ffffff;
                transition: opacity 0.3s ease;
                font-size: .75rem;
            }
        }
        
    }
    
    .product-option-text {
        padding: 1rem;
        width: 100%;
        .product-name {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            // justify-content: space-between;
            // width: 100%;
            // align-items: center;
            .concat {
                .price {
                    font-weight: 800;
                    font-size: 1.25rem;
                    color: lightgrey;
                }
                .price-suffix {
                    font-size: 0.75rem;
                    color: lightgrey;
                }
                .price-cta {
                    color: #258DBA;
                    font-weight: 800;
                }
                .price-cta-suffix {
                    font-size: 0.75rem;
                }
            }
        }
        .product-features {
            margin: 0.5em 1rem;
        }
    }
    
    &.active {
        border: 1px solid #29bd2e;
        background-color: rgba(42, 187, 47, 0.08);
        .active-marker {
            .check-contain {
                background-color: $success-color;
                svg {
                    opacity: 1;
                }
            }
        }
    }

    &:hover {
        cursor: pointer;
        border: 1px solid #29bd2e;
        background-color: rgba(42, 187, 47, 0.08);
        .active-marker {
            
        }
    }
}

.logo {
    width: fit-content;
    margin: .5rem auto;
    img {
        max-width: 200px;
    }
}

.banner-cta {
    min-height: 380px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 2rem;
    background-repeat: no-repeat;
    background-position: center;
    flex-wrap: wrap;
    border-radius: 20px;
    gap: 2rem;
    margin: 2rem;
    overflow: hidden;
    flex-direction: column;
    background-size: 400%;

    &.reverse {
        flex-direction: row-reverse;
    }

    @include respond-to(medium) {
        padding: 3rem 4rem;
        min-height: 380px;
        flex-direction: row;
        background-size: 200%;
    }

    .banner-side-1, .banner-side-2 {
        height: 100%;
        flex-basis: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 1rem;
        @include respond-to(medium) {
            flex-basis: calc(50% - 2.5rem);
        }
    }
    .super-header {
        font-size: 1rem;
        font-weight: 500;
        color: $brandPrimary;
    }
    .header {
        font-size: 2rem;
        font-weight: 800;
        span {
            color: $brandPrimary;
        }
    }
    .sub-text {
        font-size: 1.25rem;
        font-weight: 300;
    }
}

#reviews-section {
    margin: 2rem;
}

#partner-section {
    padding: 2rem;
    p {
        text-align: center;
        font-size: 1.5rem;
        font-weight: 300;
        color: grey;
        padding: 1rem 0 2rem;
    }
    .image-section{
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        gap: 1.5rem;
        justify-content: center;
        img {
            max-width: 100%;
            height: auto;
            object-fit: contain;
            @include respond-to(medium) {
            }
        }
        @include respond-to(medium) {
            flex-direction: row;
            justify-content: space-between;
        }
    }
}

#fff-video {
    aspect-ratio: 16 / 9;
    height: auto;
    width: 100%;
}

.img-stack {
    position: relative;
    height: 250px;
    width: 100%;
    #phone1-bg, #phone2-bg {
        position: absolute;
        width: 400px;
        
    }
    #phone1-bg {
        left: 50%;
        transform: translate(-50%, 0);
        bottom: -250px;
    }

    @include respond-to(medium) {
        height: 100%;
        bottom: -100px;
    }
}