@import "mapbox-gl/dist/mapbox-gl.css";
@import "./styles/_variables.scss";
@import "./styles/navigation.scss";
@import "./styles/card.scss";
@import "./styles/input.scss";

@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

* {
  padding: 0;
  margin: 0;
  font-family: "Inter", sans-serif;
  box-sizing: border-box;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

fieldset {
  border: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Inter", sans-serif;
  font-weight: 600;
}

.bold {
  font-weight: 600;
}

.light-txt {
  font-weight: 300;
}

.txt-32 {
  font-size: 32px;
}

.txt-24 {
  font-size: 24px;
}

.txt-20 {
  font-size: 20px;
}

.txt-16 {
  font-size: 16px;
}

.txt-14 {
  font-size: 14px;
}

.brand-color-1 {
  color: $brandPrimary;
}
.brand-color-1-light {
  color: $brandPrimaryLight;
}
.brand-color-2 {
  color: $brand-color-2;
}
.brand-bg-1 {
  background-color: $brand-color-1;
}
.brand-bg-2 {
  background-color: $brand-color-2;
}

.grey-color {
  color: $greySecondary;
}



// Scroll Styles
::-webkit-scrollbar {
  width: 8px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
  &:hover {
    background: #555;
  }
}
::-webkit-scrollbar-corner {
  background: #f1f1f1;
}

.link {
  text-decoration: underline;
  color: #1b8baf;
  &:hover,
  &:active,
  &:focus {
    outline: none;
    color: $brandPrimary;
    cursor: pointer;
  }
}

// General Layout for pages, need to address mobile versioning, will rewrite mobile-first
.fff-page {
  margin: 0;
  height: calc(100vh - 40px);
  overflow: hidden;
  background-color: #f9f9f9;

  .fff-page-content {
    padding: 20px 16px;
    width: 100%;
    height: 100%;
    overflow: auto;
  }

  @include respond-to(medium) {
    margin-left: 56px;
    height: 100vh;

    .fff-page-content {
      padding: 40px 64px;
      width: 100%;
      height: 100%;
    }
  }
}

// Button Styles
// Need to flesh out more, can do different things on hover such as invert from fill to outline
button {
  position: relative;
  display: inline-block;
  height: 52px;
  border-radius: 5px;
  background-color: $white;
  border: none;
  padding: 8px 20px;
  font-size: 16px;
  font-weight: 600;

  &.primary {
    &.fill {
      border: 1px solid transparent;
      background-color: $brandPrimary;
      color: $white;
      &:hover,
      &:active,
      &:focus {
        background-color: white;
        border-color: $brandPrimary;
        color: $brandPrimary;
      }
    }
    &.outline {
      border: 1px solid $brandPrimary;
      color: $brandPrimary;
      &:hover,
      &:active,
      &:focus {
        background-color: $brandPrimary;
        color: white;
      }
    }
  }

  &.success {
    &.fill {
      border: 1px solid transparent;
      background-color: $success-color;
      color: $white;
      &:hover,
      &:active,
      &:focus {
        background-color: white;
        border-color: $success-color;
        color: $success-color;
      }
    }
    &.outline {
      border: 1px solid $success-color;
      color: $success-color;
      &:hover,
      &:active,
      &:focus {
        background-color: $success-color;
        color: white;
      }
    }
  }

  &.generic {
    border: 1px solid lightgrey;
    background-color: white;
    color: black;
    font-weight: 300;
  }

  &.full-round {
    border-radius: 25px;
  }

  &:hover,
  &:active,
  &:focus {
    outline: none;
    cursor: pointer;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -4px rgba(0, 0, 0, 0.1);
  }

  &.hover-grow {
    transition: transform 0.2s ease-in-out;
    &:hover {
      transform: scale(1.05, 1.05);
    }
  }

  &.hover-shadow {
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      opacity: 0;
      border-radius: 5px;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
      transition: opacity 0.3s ease-in-out;
    }

    &:hover::after {
      opacity: 1;
    }
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  &.icon-btn {
    height: fit-content;
    width: fit-content;
    padding: 0;
    box-shadow: none;
    background-color: transparent;
    svg {
      width: 24px;
    }
  }
}

.button-group {
  display: flex;
  align-items: center;
  gap: 0.75rem;

  &.full-width {
    width: 100%;
    button {
      flex: 1;
    }
  }
}

// Standard divider line, when text is added centers the text ontop of line
.divider {
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;

  .divider-text {
    background-color: #fff;
    padding: 0 10px;
  }

  &::before,
  &::after {
    content: "";
    flex: 1;
    border-bottom: 1px solid #000;
  }
}

.map-container,
.map-controls {
  height: calc(100vh - 40px);
  overflow: hidden;

  @include respond-to(medium) {
    height: calc(100vh);
  }
}

.section {
  padding: 24px;
}
.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.section-content {
  padding: 12px;
}

#mapbox-user-marker {
  height: 20px;
  width: 20px;
  z-index: 5;
  border: 1px solid black;
  border-radius: 50%;
  background-color: #305bad;
}

.mapboxgl-canvas {
  position: absolute;
}

.grid-container {
  display: flex;
  flex-wrap: wrap;
}

// Define the number of columns
$columns: 6;

// Define the width of each column
$column-width: 100% / $columns;

// Mixin to define the column width
@mixin column-width($span: 1) {
  width: $column-width * $span;
}

// Mixin to clear floats
@mixin clear() {
  clear: both;
}

// Row container
.row {
  width: 100%;
  margin-bottom: 20px;
  clear: both;

  &::after {
    content: "";
    display: table;
    clear: both;
  }

  // Columns
  .col {
    float: left;
    box-sizing: border-box;
    padding: 5px;
    width: 100%; // Default to 100% width
    min-width: 0;

    // Clearfix
    &:nth-child(#{$columns}n + 1) {
      @include clear();
    }

    // Calculate column width based on span
    @for $i from 1 through $columns {
      &.sm-col-#{$i} {
        @include column-width($i);
      }
    }

    // Media Queries
    @media screen and (min-width: 1024px) {
      @for $i from 1 through $columns {
        &.md-col-#{$i} {
          @include column-width($i);
        }
      }
    }

    @media screen and (min-width: 1200px) {
      @for $i from 1 through $columns {
        &.lg-col-#{$i} {
          @include column-width($i);
        }
      }
    }

    @media screen and (min-width: 1400px) {
      @for $i from 1 through $columns {
        &.xl-col-#{$i} {
          @include column-width($i);
        }
      }
    }
  }
}

.hide-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;

  &::-webkit-scrollbar {
    width: 1px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
}

.data-pair {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0.25rem;
  gap: 0.25rem;
  .key,
  .value {
    flex: 1;
  }
  .key {
  }
  .value {
    font-size: 1.125rem;
    font-weight: 600;
    text-align: right;
  }
}

.fff-tabset {
  display: flex;
  flex-direction: column;
  .fff-tabs {
    display: flex;
    .fff-tab {
      padding: 8px 16px;
      border-bottom: 2px solid #f1f1f1;
      cursor: pointer;
      &.active {
        border-bottom: 2px solid $brandPrimary;
        color: $brandPrimary;
        font-weight: 600;
      }
    }
  }
  .fff-tab-content {
    margin: 16px 0 8px;
    width: 100%;
    min-height: 200px;
    position: relative;

    &::after,
    &::before {
      display: block;
      content: "";
      background: #000;
      height: 1rem;
      position: absolute;
      width: 100%;
      z-index: 3;
      pointer-events: none;
    }

    &::after {
      bottom: 0;
      background: linear-gradient(to top, rgba(0, 0, 0, 0.1), transparent 50%);
    }
    &::before {
      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.1),
        transparent 50%
      );
    }
  }
}

.waypoint-color {
  height: 20px;
  width: 20px;
}

// DATA LIST STYLES - TODO - Move to own file
.data-list {
  padding: 16px;
  background-color: #f1f1f1;
  overflow: overlay;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
  min-height: 200px;
  max-height: 500px;

  .no-data-message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    flex: 1;
  }

  .data-list-filter {
    position: absolute;
    z-index: 2;
    background: white;
    top: 0;
    left: 50%;
    width: 98%;
    max-height: 100%;
    transform: translateX(-50%);
    box-shadow: 0px 12px 10px 0px rgba(0, 0, 0, 0.15);
    border-radius: 0 0 0.75rem 0.75rem;

    .filter-content {
      height: 0;
      opacity: 0;
      transition: height 0.3s ease;
      max-height: 250px;
    }

    &.expanded {
      .filter-content {
        transition: height 0.3s ease, opacity 0.2s ease-in;
        height: 100%;
        opacity: 1;
      }
    }
    .filter-tab {
      position: absolute;
      height: 30px;
      width: fit-content;
      background: $brandPrimary;
      z-index: 2;
      right: 2rem;
      bottom: -30px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.5rem;
      box-shadow: 0px 12px 10px 0px rgba(0, 0, 0, 0.15);
      color: white;
      border-radius: 0 0 0.5rem 0.5rem;
      cursor: pointer;
    }
  }
}

.waypoint-data-card {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  .data-title {
    display: flex;
    gap: 0.5rem;
  }
}

.shorts-list {
  display: flex;
  gap: 1.5rem;
  overflow-x: auto;
}
.youtubeShorts-video {
  border-style: none;
}
