.notification-container {
  position: fixed;
  top: 1rem;
  right: 1rem;
  z-index: 1000;
}

.notification {
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 5px;
  color: white;
  animation: fadeInOut 3s ease-in-out forwards;
}

.notification-info {
  background-color: blue;
}

.notification-success {
  background-color: green;
}

.notification-warning {
  background-color: orange;
}

.notification-error {
  background-color: red;
}

@keyframes fadeInOut {
  0%,
  100% {
    opacity: 0;
  }
  10%,
  90% {
    opacity: 1;
  }
}
