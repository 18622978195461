.loader-backdrop {
	position: absolute;
	height: 100%;
	width: 100%;
	backdrop-filter: blur(5px);
}
.loader {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 100%;
	height: 100%;
	.loader-icon {
		display: inline-block;
		width: 100px;
		height: 100px;
		border-radius: 50%;
		background-image: url("../assets/img/logoPrimary.svg");
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center;
		transform-origin: center;
		animation: loader-icon 4s cubic-bezier(0.52, 0.14, 0.63, 0.91) infinite;
	}
}

@keyframes loader-icon {
	0%,
	100% {
		animation-timing-function: cubic-bezier(0.87, 0.39, 0.32, 1.38);
	}
	0% {
		transform: rotateY(0deg);
	}
	50% {
		transform: rotateY(1800deg);
		animation-timing-function: cubic-bezier(0.52, 0.14, 0.63, 0.91);
	}
	100% {
		transform: rotateY(3600deg);
	}
}




