@import "./variables";

.card {
  min-width: 300px;
  width: fit-content;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -4px rgba(0, 0, 0, 0.1);
  padding: 24px;
  border-radius: 0.75rem;
  background-color: white;
  position: relative;
  .stat-card {
    height: 4em;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 16px;
    .stat-icon {
      width: 24px;
      color: $brandPrimary;
    }
  }
  &.full {
    width: 100%;
  }

  &.disabled {
    background-color: rgba(255, 255, 255, 0.15);
    box-shadow: 0 6px 7px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -4px rgba(0, 0, 0, 0.1);
    .stat-card {
      opacity: 0.15;
    }
  }

  &.clickable:not(.disabled) {
    transition: all 0.3s ease;
    &:hover {
      transform: scale(1.02);
      cursor: pointer;
    }
  }

  .card-disabled {
    cursor: not-allowed;
    top: 0;
    left: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(
      160deg,
      rgba(255, 255, 255, 0.1) 35%,
      rgba(136, 136, 136, 0.1) 100%
    );
    p {
      color: $brandPrimary;
      font-weight: 600;
      font-size: 1.125rem;
    }
    border-radius: inherit;
  }
}
